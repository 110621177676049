import React, { FC } from 'react';
import { graphql } from 'gatsby';

import SectionTitle from 'common/SectionTitle';
import PageSchema from 'common/PageSchema';
import createBreadcrumbs from 'utils/createBreadcrumbs';
import Layout from 'layout/Layout';

import 'styles/main.scss';
import './ContactPage.scss';
import { ContactPageProps } from './models';

const ContactPage: FC<ContactPageProps> = ({
  data: {
    contactPage: {
      title,
      description,
      phoneNumber,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoNoIndex,
    },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  const displayContactInfo = () => (
    <div className="contact-page__content">
      <SectionTitle text={title} />
      <p className="contact-page__paragraph">{description}</p>
      <p className="contact-page__paragraph">{phoneNumber}</p>
    </div>
  );

  return (
    <Layout
      className="contact-page"
      headerTransparent
      breadcrumbs={breadcrumbs}
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoNoIndex }}
    >
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {displayContactInfo()}
    </Layout>
  );
};

export const query = graphql`
  query {
    contactPage {
      title
      description
      phoneNumber
      ...SeoFragment
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ContactPage;
